// import $ from './vendors/jquery';
import './vendors/h5bp';
import './vendors/fancybox';


/* shanky */
$(document).ready(function() {


  $('.f-pic').fancybox();

  $('.carousel-one').slick( {
    dots: false,
    arrows: false,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 3000
  });
  $('.carousel-news').slick( {
    dots: true,
    arrows: false,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 3000
  });

  /*
   * Same height in all products
   */  
	var maxHeight = Math.max.apply(null, $(".product-category a").map(function () {
	    return $(this).height();
	}).get());
	$(".product-category a").height(maxHeight);

	$( window ).resize(function() {
		var maxHeight = Math.max.apply(null, $(".product-category a").map(function () {
		    return $(this).height();
		}).get());
		$(".product-category a").height(maxHeight);
	});

    /* google map */
    var markers = [
        ['<b>Chopperas Rossi<br>Entre Ríos 1782<br>San Francisco - Córdoba', -31.418536, -62.083540]        
    ];
    var latlng = new google.maps.LatLng(-31.418536, -62.083540);
    var mapOptions = {
    zoom: 15,
    center: latlng,
    mapTypeControl: false,
    scaleControl: false,
    panControl: false,
    rotateControl: false,
    zoomControl: false,
    streetViewControl: false,
    styles: [ { "featureType": "landscape", "stylers": [ { "color": "#FFFFFF" } ] },{ "featureType": "poi", "elementType": "geometry", "stylers": [ { "color": "#CC0000" } ] },{ "featureType": "water", "stylers": [ { "color": "#00415E" } ] } ]
    };
    var map = new google.maps.Map(document.getElementById("map-canvas"),mapOptions);
    var infowindow = new google.maps.InfoWindow(), marker, i;
    for (i = 0; i < markers.length; i++) {  
        marker = new google.maps.Marker({
            position: new google.maps.LatLng(markers[i][1], markers[i][2]),
            map: map,
            icon: "../assets/media/ubicacion.png"
        });
        google.maps.event.addListener(marker, 'click', (function(marker, i) {
            return function() {
                infowindow.setContent(markers[i][0]);
                infowindow.open(map, marker);
            }
        })(marker, i));
    }

});


